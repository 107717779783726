@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Vollkorn:ital,wght@0,400..900;1,400..900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:
    linear-gradient(rgba(255,255,255,.5), white),
    url('/public/noise.svg');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.linkedinprofile {
  background-image: url(/public/profile.png), linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
  background-size: cover;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  height: 50vh;
  width: 100%;
  max-width: 90vw;
  border-radius: 15px;
  box-shadow: inset 0 -100px 100px rgba(255,255,255,.5); 
  transition: all 700ms ease;  /* Transition for all properties */
  width: 700px;
  margin: 0px auto;
}

.linkedinprofile:hover {
  background-position: 0% 70%;
  box-shadow: inset 0 0px 0px rgba(255,255,255,.5);
}